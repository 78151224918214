.topcanvasBlock{
    padding-top:6em;
}

.financeLandingPage{
    .flex-container {
        .flexItem{
            width:175px
        }
    }
}