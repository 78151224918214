@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Regular'), 
       local('Roboto-Regular'), 
       url('./assets/Roboto/Roboto-Regular.ttf') 
       format('woff');
}
body {
  font-family: 'Roboto';
  font-size:16px;
  margin:0;
  padding:0;
  line-height: 21px;
  background: #f6f3e3;
}

.mainContainer {
  margin: 0 auto;
  font-size: 12px;
 
 .mb-20{margin-bottom: 70px;}
 .topHeader {
  min-height: 50px;
  vertical-align: middle;
  display: table;
  width: 100%;
  background: #407eaf;
  letter-spacing: 2px;
  margin:0;
  position: fixed;
  top: 0;
  z-index: 999;
  box-shadow: 0 4px 20px 0px #000, 0 7px 12px -5px #407eaf;

  &>.col, svg {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      font-size: 18px;
      color: #fff;
      min-height: 30px;
  }
  svg{
    margin-left:-10px;
  }
}
.tabsBlock{
  overflow-x: auto;
  overflow-y: hidden;
  display: -webkit-box;
  display: -moz-box;
  white-space: nowrap;
  &>a {
      display: inline-block;
      text-align: center;
      padding: 7px;
      text-decoration: none;
       margin-right: 10px;
      
      border-radius: 6px;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 12px;
      color: #000;
      background: #fff;
      border: 1px solid #407eaf;
      box-shadow: 0 4px 10px #407eaf75;
      border: 1px solid #407eaf;
  }
  &>a.active {
      color: #fff;
      background: linear-gradient(to right, #407eaf, #407eaf, #407eaf);
      border: 1px solid transparent;

  }
}
.backtohomepage{
  a{
    color: #fff;
    vertical-align: middle;
    margin-left: 12px;
    position: absolute;
    top: 9px;
    z-index: 999;
  }
}
}
.table-striped tbody tr:nth-of-type(odd){
  background-color: #a0caf37a;
}
.table th, .table td {
  border-top: 1px solid #467eb6;
}
.table thead th {
    border-bottom: 1px solid #467eb6;
}
.topContSpace{
  margin-top: 50px;
}
.homePageTopSpace{
  padding-top: 80px!important;
}
.contP, .formBlock{
  padding-left:30px;
  padding-right:30px;
}
.formBlock{
  .form-label{
    font-weight: bold;
  }
 input,select{
   font-size: 12px;
 }
}

.emergencyBlock{
  margin-bottom: 50px;}


  .loader {
    border: 10px solid transparent;
    border-radius: 50%;
    border-top: 10px solid #665eff;
    border-bottom: 10px solid #ff0099;
    width: 70px;
    height: 70px;
    animation: spin 0.9s linear infinite;
    margin: 0 auto;
    margin-top: 32%;
  }
  .loadingText{
    text-align: center;
    margin-top: 3px;
    color:#cdcdcd
  }

  .wrapperLogout {
    position: absolute;
    top: 0;
    width: 100%;
    height: 300vh;
    left: 0;
    background: #2b2b2b;
    opacity: 0.4;
    text-align: center;
    z-index: 9999;
    margin:0 auto;
}
.waitIconWrapper{
  text-align: center;
  margin: 0 auto;
}
.btnAsContent, .btnAsContent:hover,.btnAsContent:focus{
  border: none!important;
  background: transparent!important;
  color:#000;
}
.error {
  color: #f02630!important;
  font-size: 12px;
}
.loginForm, .registrationForm {
  margin-top: 70px;
}
.logo img{
  max-width: 32px;
  height: 32px;
  margin-top: 0px;
  margin-right: 10px;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #cdcdcd;
  background-color: #dde1e4;
  border-color: #dde1e4;
}

.waterUSageBlock table{
  display: inline-table;
}

.mandatoryField {
  color: #c60a0a;
}

.fullScreenWait{
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  .wrapper{
    background: #2b2b2b;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
  }
  .waitIcon{z-index: 9999;
    position: absolute;
    top: 0;
    left: 0;}
}
.txtgreen{
  color:green!important;
}
.txtred{
  color:red!important
}
.cardHeaderBlock{
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
  // background: #00d3ee;
  border-radius: 0;
  box-shadow: 0 1px 10px #00d3ee, 0 1px 10px #000;
}
body.darktheme{
background: #11464d;
.cardHeaderBlock{
  background: #11464d!important;
  box-shadow: 0 1px 10px #000!important;
  color:#fff;
  padding:15px 20px;
}
.profileContainer{
  background: #fff;
  height:100vh
}
.tab-content{
  background: #fff!important;
  border:1px solid #9c27b0!important
}
.form-group {
  margin-bottom: 36px;
}
input.form-control{
  border: 0;
    background: transparent;
    border-bottom: 1px solid #9c27b0;
    border-radius: 0;
}
.nav-item.active{
  background: linear-gradient(to top, #9c27b0, #c002e042, #9c27b0)!important;
}
.amenities, .communityBlock, .residentDirTabs,.waterUSageBlock, .boardBlock, .emergencyBlock {

  background: #fff;
  //padding: 10px 30px!important;
}

}
.localServicesTabs h6 {
  font-weight: bold;
  margin-left: 24px;
}
.logoutOptionsIcon{
  width: 20px;
  height: 40px;
  position: relative;
  margin: 5px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;


 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

 span:nth-child(1) {
  top: 8px;
}

 span:nth-child(2) {
  top: 16px;
}

 span:nth-child(3) {
  top: 25px;
}
}
.logoutOptionsIcon.open{
  span:nth-child(1) {
    top: 18px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }
  span:nth-child(3) {
    top: 18px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }

}

.modal.fade .modal-dialog{
  -webkit-transform: translate(0,0);
  transform: translate(0,0);
}

.zoom-in {
  top:40px!important;
  transform: scale(0)!important;
  opacity: 0;
  -webkit-transition: 1.5s all 0s;
  -moz-transition: 1.5s all 0s;
  -ms-transition: 1.5s all 0s;
  -o-transition: 1.5s all 0s;
  transition: 1.5s all 0s;
  display: block !important;
}

.zoom-in.show {
  opacity: 1;
  transform: scale(1)!important;
  transform:none;
}
.menuHeader {
  color: #fff;
  font-weight: normal;
  font-size: 16px;
  background: linear-gradient(
167deg
, black, #7d9588);
  padding: 10px 11px;
  margin-left: -15px;
  margin-right: -15px;
}

.deviceContainer {
  h6 {
    margin-top: 30px;
    color: #2b2b2b;
    background: linear-gradient(
  45deg
  , #c9bbbb, transparent);
    padding: 10px 20px;
    margin-left: -15px;
    box-shadow: 0 3px 6px #cdcdcd;
  }
  .diviceCol {
    color: #3736d4;
    strong{
      margin-right: 10px;
      color: #795548;
    }
    span {
      min-width: 200px;
      display: inline-block;
      margin-top: 16px;
      line-height: 25px;
  }
  .btn {
    float: right;
    background: transparent;
    color: red;
    border: 0;
    margin-top: 11px;
}
  }
}

.signupBlock{
  border-top: 1px solid #cdcdcd;
    margin-top: 30px;
    padding-top: 30px;
    font-size: 15px;
    color: #974cbf;
    p{
      margin: 0;
    }
    svg{
      font-size: 26px;
    margin-right: 12px;
    }
.registrationBtn {
  padding: 0;
 
  color: #972eb0;
  font-weight: bold;
}
}
.registrationForm{
  legend {
    font-size: 15px;
    font-weight: 700;
  }
  .rtype .form-label {
    margin-right: 19px;
    font-size: 19px;
    margin-top: 10px;
    margin-bottom: 0;
}
}
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .directory{
    padding-top: 50px;
    .tableContainer{
     table{
      background: #fff;
      .firstCol{
        min-width: 80px!important;
      }
     }
    .table th, .table td {
    border-top: 1px solid #467eb6;
    min-width: 140px;
    text-align: left;
    padding: 10px;
    }
}}



.updateMaintenance{

    button{
      float: left;
    }
  
}

.adminTable{
  th,td{
    font-size: 11px;
    padding: 5px;
    text-align: center;
    text-transform: capitalize;
    word-break: break-all;
  }
  th:first-child,td:first-child{
    display:none
  }
}
@media (min-width:1025px){
  .mainTabsBottom {
    position: absolute;
    bottom: 0;
    border-top: 1px solid #cdcdcd;
    background: #2a2e43;
    left: 0;
    top: 53px;
    max-width: 110px;
    a{
     
    width: 107px!important;
    display: inline-block!important;
    text-align: center;
   
    }
    .active{
      border-radius: 0 11px 11px 0!important;
    }
}
.topContSpace {
  margin-top: 50px;
  margin-left: 120px!important;
}
.localServicesTabs {
  padding: 34px 25px!important;
  background: #fff!important;
}
.formBlock{
  max-width:500px!important
}
.showPaid{
  .pending, .receivedcash {
    display: none;
  }
}
.showPending {
  .receivedonline, .receivedcash  {
    display: none;
  }
}
.showCash{
  .receivedonline, .pending  {
    display: none;
  }
}

}










