.flex-container {
    display: flex;
    flex: auto;
    flex-flow: row wrap;
    align-content: center;
    justify-content: space-evenly;
    margin-bottom: 94px;
    margin-top: 50px;
    .flexItem {
        text-align: center;
        background: #fff;
        width: 115px;
        margin:10px;
        margin-top: 15px;
        padding: 20px;
        border-radius: 8px;
        border: 1px solid #cdcdcd;
        box-shadow: 0px 2px 10px #cdcdcd;
        font-size: 10px;
        line-height: 15px;
        &> img {
            max-width: 60px;
            max-height: 70px;
        }
        .societyText {
            margin-top: 5px;
            color:#000;
        }
    }
}
@media (min-width: 300px) and (max-width: 400px) {
    .flex-container {
        .flexItem {
            width:100px
           
        }
    }

}
@media (min-width: 600px) and (max-width: 799px) {
    .flex-container {
        .flexItem {
            width:135px
           
        }
    }

}
@media (min-width: 800px) and (max-width: 899px) {
    .flex-container {
        .flexItem {
            width:180px;
        }
    }
}

@media (min-width: 900px) {
    .flex-container {
        .flexItem {
            width:211px
        }
    }
}
@media (min-width: 1050px) {
    .flex-container {
        .flexItem {
            width: 215px;
            margin: 20px;
        }
    }
}