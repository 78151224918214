.changePassword {
    padding: 40px 40px 40px;
}
.profilepicBlock{
    .fileInput{
        display:none
    }
    .custom-file-upload {
        border: 1px solid #407eaf;
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;
        background: #752e97;
        color: #fff;
        border-radius: 4px;
        margin-top: 24px;
    }
    .imgPreview{
        width: 200px;
        margin: 30px auto;
        height: 200px;
        padding: 20px;
        box-shadow: 2px 0px 10px #cdcdcd;
        img{
            max-width: 100%;
            max-height: 100%;
        }
    }
}
.editlocalservice{
    padding-left:20px;
    .editLocalserviceForm{
        margin-bottom: 36px;
        border-bottom: 1px solid #cdcdcd;
        padding-bottom: 65px;
        .form-group{
           
            .form-label{
                float: left;
                font-size: 14px;
                margin-bottom: 0;
                line-height: 36px;
                min-width: 75px;
            }
            .form-control{
                width:60%;
                
            }
            .custom-select{
                width: 60%;
        border: 0;
        border-bottom: 1px solid #9c27b0;
        border-radius: 0;
            }
        }
    }
   
   
}