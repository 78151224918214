.loginTabs{
  margin-top:80px;
}

.tab-content{
  margin-top:30px
}

.form-label{font-weight: bold;}
.alreadyLoggedin button {
  border: none;
  background: transparent;
  text-decoration: underline;
  font-weight: bold;
  color: #407eaf;
}
.loginForm,.registrationForm{
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
    border: 0;
    display: flex;
    position: relative;
    font-size: .875rem;
    min-width: 0;
    word-wrap: break-word;
    background: #fff;
    box-shadow: 0 2px 2px 0 #cdcdcd;
    margin: 100px auto 50px;
    transition: all 300ms linear;
    border-radius: 6px;
    margin-bottom: 30px;
    flex-direction: column;
    padding: 60px 20px;
    max-width: 320px;
  h2{
    color: #fff;
    background: linear-gradient(60deg, #ab47bc, #8e24aa);
    box-shadow: 0 12px 20px #cdcdcd;
    width: auto;
    padding: 20px 0;
    font-size: 24px;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 43px;
    border: 0;
    border-radius: 5px;
    margin-top: -90px;

  }
  .form-group{
    margin-bottom: 40px;
  }
  input.form-control {
    border: 0;
    border-bottom: 1px solid #cdcdcd;
    border-radius: 0;
   
}
 
}
  
  

  