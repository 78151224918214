.mainTabsBottom {
    position: fixed;
    bottom: 0;
    border-top: 1px solid #cdcdcd;
    width: 100%;
    background: #2a2e43;

    a {
        background: transparent;
        border: 0;
        color: #fff;
        width: 20%;
        display: inline-block;
        text-align: center;
        vertical-align: -webkit-baseline-middle;
        padding:5px 0;

        .bottomNavIcon {
            font-size: 25px;
        }
        

        .bottomNavText {
            width: 100%;
            display: inline-block;
            font-size: 10px;
        }

        .currency {
            line-height: 15px;
           
            vertical-align: bottom;
            padding-top: 10px;
            display: inline-block;
            margin-bottom: 2px;
        }
    }

    .active {
        // background: #665eff;
        background: #407eaf;
        margin-top: -10px;
        border-radius: 11px 11px 0 0;
        color: #fff;
        height: 70px;
        padding-top: 14px;
        &>svg, .bottomNavText{
            fill:#fff;
            color: #fefefe;
        }
       
    }
}