.noticeBoard {
    margin-bottom: 60px;

    h3 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0;
        margin-top: 23px;
        color: #195789;
    }

    .noticeContainer {
        line-height: 1.5;
        border-bottom: 1px solid #cdcdcd;
        font-size: 12px;
        padding: 10px 20px;

        .collapse:not(.show) {
            display: block !important;
            height: 40px;
            overflow: hidden;
            margin-bottom: 4px;
        }

        #collapseExample.collapsing {
            height: 3rem;
        }

        a.collapsed::after {
            content: '+ Read More';
        }

        a:not(.collapsed)::after {
            content: '- Read Less';
        }
    }

    .cName {
        font-size: 11px;
        margin-top: 4px;
        display: inline-block;
    }
}

.localServicesTabs {
    margin: 50px 25px;

    .localTabs {
        overflow-x: auto;
        overflow-y: hidden;
        display: -webkit-box;
        display: -moz-box;
        white-space: nowrap;

        &>.nav-item {
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin-right: 10px;
            margin-top: 30px;
            font-size: 12px;
            color: #000;
            background: #fff;
            border: 1px solid #8e8d88;
            box-shadow: 0 4px 10px #8e8d88a1;
        }

        &>.nav-item.active {
            color: #fff;
            background: linear-gradient(to top, #d6cfad, #8e8d88, #c0beb4);
            font-weight: 400;
            border: 1px solid #40af4d52;
            // color: #fff;
            // background: linear-gradient(to right, #407eaf, #407eaf, #407eaf);
            // border: 1px solid transparent;
            //         color: #fff;
            // background: linear-gradient(to top, #40af4d78, #40af4dbf, #40af4de0);
            // font-weight: 400;
            // border: 1px solid transparent;

        }
    }

    .tab-content {
        padding: 20px 10px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        margin-top: 0;
        border: 1px solid #40af4d52;
        box-shadow: 0 4px 10px #cdcdcd;
    }

    .introBlock {

        display: inline-block;
        width: 100%;
        border-bottom: 1px solid #cdcdcd;
        padding-bottom: 10px;

        .imageBlock {
            float: left;
            margin-top: 0;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            overflow: hidden;
            box-shadow: 4px 3px 10px #cdcdcd;

            img {
                max-width: 100%;
            }
        }

        .descriptionBlock {
            float: left;
            margin-left: 20px;
            margin-top: 13px;
            font-size: 12px;
            line-height: 25px;

            .label {
                font-weight: bold;
                min-width: 80px;
                margin-left: 20px;
            }
        }
    }
}

.communityTabs {
    .descriptionBlock {
        .row {
            margin-bottom: 20px;
            border-bottom: 1px solid #407eaf;
            padding-bottom: 20px;

            &>div {
                width: 100%;
                margin-left: 20px;
                font-size: 14px;
            }
        }

        h5 {
            width: 100%;
            font-size: 18px;
            margin-bottom: 5px;
            color: #407eaf;
        }
    }

}

.amenities,
.communityBlock,
.residentDirTabs {
    font-size: 12px;
    padding-bottom: 80px;
}

.amenities {
    .form-label {
        min-width: 65px;
    }

    .react-datepicker-wrapper {
        display: inline-block;

        border: 1px solid #ced4da;
        box-shadow: none;
        margin-left: 5px;
        border-radius: 0.25rem;
        padding: 5px;

        input {
            border: 0
        }
    }
}

.updateMaintenance {
    padding-bottom: 100px;
    .form-group {
       
        margin-bottom: 0!important;
        float: left;
        
    }
    .updateForm{
        input{
            float: left;
    max-width: 150px;
    font-size: 12px;
    padding: 0;
    margin-left: 30px;
        }
    }
    .tableContainer th,
    .tableContainer td {
        text-align: center;
    }

    select.custom-select {
        max-width: 132px;
        font-size: 12px;
        float: left;
    }

    button {
        float: left;
        font-size: 12px;
        padding: 3px 20px;
        margin-right: 5em;
        color: #fff;
        margin-top: 3px;
        margin-left:30px
    }
    .spinner-border{
        display: none;
    float: left;
    margin-left: 4em;
    }
    .formStatus{
        line-height: 16px;
    font-size: 11px;
    visibility: hidden;
    
    max-width: 106px;
    display: inline-block;
    text-align: center;
    
    }

}